import { ga } from "client/utils/adsense/ga";
import { gtag } from "client/utils/adsense/gtag";
import { useRouter } from "next/router";
import { setImage } from "store/features/resizeEditor/actions";
import { useAppDispatch } from "store/store";
import { useUploader } from "../useUploader";
import { reset } from "store/features/resizeBackgroundImport/actions";
import { getImageExtension } from "client/utils/getImageExtension";
import translatedRoutedSlugs, { type TranslatedRouteSlugs } from "translatedRouteSlugs";
import { ImageUploaderOptions } from "components/common/Uploader/ImageUploader/useImageUploader";
import { useToast } from "components/common/Toast/useToast";
import { DEFAULT_TOAST_SHOW_TIME } from "components/common/Toast/ToastRenderer";
import { translateFunc } from "client/utils/translateFunc";
import { useEffect } from "react";

export const useResizeUploader = () => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const { toast } = useToast();
    const { query } = useRouter();

    const handleProceed = () => {
        const task = images[0]?.task;
        if (!task) return;

        const image = images[0];
        if (!image.file) return;
        const url =
            image.upload.type === "device" ? image.file.src : image.task?.result?.url?.toString() ?? image.file.src;
        dispatch(
            setImage({
                src: url,
                height: 0,
                width: 0,
                initialHeight: 0,
                initialWidth: 0,
                originalHeight: 0,
                originalWidth: 0,
                containerWidth: 0,
                containerHeight: 0,
                ratio: 1,
                title: image.file.file.name,
                ext: getImageExtension(image.file.file),
            }),
        );
        dispatch(reset());
        router.push(
            `/${router.locale === "en" ? "" : `${router.locale}/`}${translatedRoutedSlugs["/resize/editor/:taskId"]?.[
                (router.locale as keyof TranslatedRouteSlugs["/resize/editor/:taskId"]) ?? "en"
            ]?.replace(":taskId", task.id)}`,
        );
    };

    const handleImageLoadedAnalytics = () => {
        ga("send", {
            hitType: "pageview",
            page: "/vp/image_resizer/image_loaded/",
            title: "Image Resizer Image Loaded",
        });

        // event
        gtag("event", "Image Loaded", {
            event_category: "Image Resizer",
            event_label: "Upload Page",
        });
    };

    const { images, deleteImage, deleteAllImages } = useUploader(handleProceed, handleImageLoadedAnalytics);

    const filterFiles: NonNullable<ImageUploaderOptions["filterFiles"]> = (files) => {
        if (files.length > 1) handleExtraFilesUploaded(files.length);
        return [files[0]];
    };

    const toastText = translateFunc("use-our-bulk-resize-tool-to-batch-resize-images");
    function handleExtraFilesUploaded(filesLength: number) {
        toast({
            closeable: true,
            position: "top-right",
            status: "success",
            text: toastText,
            time: DEFAULT_TOAST_SHOW_TIME,
        });
        if (filesLength > 10) {
            gtag("event", "Select Image", {
                event_category: "Image Resizer",
                event_label: "Batch upload on single resizer",
            });
        }
    }

    const errorFromEditorText = translateFunc(`corrupt-image-toast-test`);
    useEffect(() => {
        if (query["errorFromEditor"] === "true") {
            toast({
                closeable: true,
                position: "top-right",
                status: "success",
                text: errorFromEditorText,
                time: DEFAULT_TOAST_SHOW_TIME,
            });
        }
    }, [query]);

    return { deleteImage, deleteAllImages, filterFiles };
};
